import { Box, Stack, Typography } from "@mui/material";
import { useClientMeetings } from "@trainwell/features";
import { isFuture } from "date-fns";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { hideChat } from "src/lib/frontChat";
import { handleAuthWeak } from "src/slices/authSlice";
import LoadingPage from "../misc/LoadingPage";
import CallCard from "./CallCard";

export default function CallsPage() {
  const client = useAppSelector((state) => state.client.client);
  const { data: calls } = useClientMeetings({
    userId: client?.user_id ?? "",
  });
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    hideChat();
  });

  useEffect(() => {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const foundClientID = urlParams.get("user_id");

    if (foundClientID && !client) {
      dispatch(handleAuthWeak(foundClientID));
    }
  }, [dispatch, location]);

  if (!client) {
    return <LoadingPage message="Loading client" />;
  }

  if (
    client.account.membership.state !== "active" &&
    client.account.membership.state !== "past_due"
  ) {
    return <Navigate to="/" />;
  }

  if (!calls) {
    return <LoadingPage message="Loading calls" />;
  }

  const upcomingCalls = calls
    ?.filter((call) => isFuture(call.date_start))
    .sort((a, b) =>
      (a.date_start as string).localeCompare(b.date_start as string),
    );
  const pastCalls = calls
    ?.filter((call) => !isFuture(call.date_start))
    .sort((a, b) =>
      (b.date_start as string).localeCompare(a.date_start as string),
    );

  return (
    <Box
      sx={{
        py: 3,
        px: { xs: 2, sm: 3 },
        mb: { xs: 2, sm: 4 },
        maxWidth: "md",
      }}
    >
      <Typography variant="h1" sx={{ mb: 2 }}>
        Calls
      </Typography>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Upcoming
      </Typography>
      {upcomingCalls.length ? (
        <Stack spacing={2}>
          {upcomingCalls.map((call) => (
            <CallCard key={call.id} call={call} />
          ))}
        </Stack>
      ) : (
        <Typography
          variant="body2"
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          No upcoming calls
        </Typography>
      )}
      <Typography variant="h3" sx={{ mb: 2, mt: 4 }}>
        History
      </Typography>
      {pastCalls.length ? (
        <Stack spacing={2}>
          {pastCalls.map((call) => (
            <CallCard key={call.id} call={call} />
          ))}
        </Stack>
      ) : (
        <Typography
          variant="body2"
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          No call history
        </Typography>
      )}
    </Box>
  );
}
