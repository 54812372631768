import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { getSanitizedTrainerName } from "@trainwell/features";
import type { Trainer } from "@trainwell/types";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import CoachCard from "src/components/ChangeCoachPage/CoachCard";
import LoadingPage from "src/components/misc/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { handleAuthWeak } from "src/slices/authSlice";
import {
  getPotentialTrainers,
  setForcedTrainerID,
  setNewTrainerID,
} from "src/slices/switchTrainerSlice";

export default function ChangeCoachPage() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const client = useAppSelector((state) => state.client.client);
  const clientStatus = useAppSelector((state) => state.client.status);
  const potentialTrainers = useAppSelector(
    (state) => state.switchTrainer.potentialTrainers,
  );
  const forcedTrainerId = useAppSelector(
    (state) => state.switchTrainer.forcedTrainerId,
  );
  const [changingCoach, setChangingCoach] = useState(false);
  const [showAllCoaches, setShowAllCoaches] = useState(false);
  const [changeToTrainerId, setChangeToTrainerId] = useState<string | null>(
    null,
  );
  const [changeToTrainer, setChangeToTrainer] = useState<Trainer | null>(null);

  useEffect(() => {
    if (changeToTrainerId) {
      api.trainers.getOne(changeToTrainerId).then((t) => {
        setChangeToTrainer(t);
      });
    } else {
      setChangeToTrainer(null);
    }
  }, [changeToTrainerId]);

  useEffect(() => {
    if (client?.user_id) {
      dispatch(getPotentialTrainers({ userId: client.user_id }));
    }
  }, [dispatch, client?.user_id]);

  useEffect(() => {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const foundClientID = urlParams.get("user_id");

    if (foundClientID) {
      dispatch(handleAuthWeak(foundClientID));
    }

    const foundTrainerID = urlParams.get("trainer_id");

    if (foundTrainerID) {
      dispatch(setForcedTrainerID(foundTrainerID));
    }
  }, [dispatch, location]);

  let trainerIds = (potentialTrainers ?? []).map(
    (trainer) => trainer.trainer_id,
  );

  if (forcedTrainerId) {
    trainerIds = trainerIds.filter((id) => id !== forcedTrainerId);
    trainerIds.unshift(forcedTrainerId);
  }

  if (!showAllCoaches) {
    trainerIds = trainerIds.slice(0, 3);
  }

  const topChoiceIndex = 0;

  if (forcedTrainerId && !showAllCoaches) {
    trainerIds = trainerIds.slice(0, 1);
  }

  if (clientStatus === "loading" || clientStatus === "idle") {
    return <LoadingPage message="Loading client" />;
  }

  if (!potentialTrainers) {
    return <LoadingPage message="Finding your best trainers" />;
  }

  if (!client?.user_id) {
    return (
      <Container maxWidth="md">
        <Typography variant="h1" sx={{ mb: 4, textAlign: "center" }}>
          We ran into a problem finding your user id
        </Typography>
      </Container>
    );
  }

  function selectTrainer(trainerID: string) {
    setChangingCoach(true);
    api.clients
      .changeCoach({
        userId: client!.user_id,
        trainerId: trainerID,
        reason: "manual_client",
      })
      .then(() => {
        dispatch(setNewTrainerID(trainerID));

        navigate("/schedule-call" + location.search);
      });
  }

  const isFreemium = client?.account.plan.id === "freemium";

  if (isFreemium || client.disable_manual_coach_switching === true) {
    return <Navigate to={"/" + location.search} />;
  }

  return (
    <Container maxWidth="md" sx={{ my: 3 }}>
      <Typography variant="h1">Choose a new trainer</Typography>
      <Typography sx={{ mb: 4 }}>
        You can switch trainers anytime at no cost, we want you to have the best
        match possible!
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {trainerIds.length === 1 && <Grid item xs={12} sm={4} />}
        {trainerIds.map((trainerId, index) => (
          <Grid item xs={12} sm={4} key={trainerId}>
            <CoachCard
              isTopChoice={index === topChoiceIndex}
              trainerID={trainerId}
              selectTrainerHandler={(newTrainerId) => {
                setChangeToTrainerId(newTrainerId);
              }}
              loading={changingCoach}
            />
          </Grid>
        ))}
      </Grid>
      {!showAllCoaches && potentialTrainers.length > 3 && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Button
            onClick={() => {
              setShowAllCoaches(true);
            }}
            startIcon={<KeyboardArrowDownRoundedIcon />}
            variant="outlined"
          >
            See more trainers
          </Button>
        </Box>
      )}
      <Dialog
        open={Boolean(changeToTrainer)}
        onClose={() => {
          setChangeToTrainerId(null);
        }}
      >
        {changeToTrainer && (
          <>
            <DialogTitle>
              Switch to {getSanitizedTrainerName(changeToTrainer)}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>Please note that:</DialogContentText>
              <DialogContentText sx={{ ml: 2 }}>
                1. You will switch trainers immediately.
                <br />
                2. Any calls you have scheduled with your current trainer will
                be cancelled.
                <br />
                3. You will not be able to access your chat history with your
                current trainer.
                <br />
                4. You will keep your workouts and other data.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                onClick={() => {
                  setChangeToTrainerId(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  selectTrainer(changeToTrainer.trainer_id);
                }}
              >
                Switch to {getSanitizedTrainerName(changeToTrainer)}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Container>
  );
}
